import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from './Loader'

export default function Account(props) {
	let themeType = props.theme
	let ringOffsetClass = 'focus:ring-offset-white'

	if (props.currentBg === 'primary') {
		ringOffsetClass = 'focus:ring-offset-primary-500'
	}

	let theme = {
		primary: {
			normal: 'bg-primary-500',
			hover: 'hover:bg-primary-600',
			focus: 'focus:ring-primary-500',
			border: 'border-primary-500',
			borderhover: 'hover:border-primary-700',
			text: 'text-white',
			loader: 'text-white',
		},
		secondary: {
			normal: 'bg-corporate',
			hover: 'hover:bg-primary-300',
			focus: 'focus:ring-primary-200',
			border: 'border-primary-200',
			borderhover: 'hover:border-primary-400',
			text: 'text-primary-500',
			loader: 'text-white',
		},
	}

	if (props.value === props.account) {
		themeType = 'primary'
	} else {
		themeType = 'secondary'
	}

	const btn = theme[themeType]

	return (
		<>
			<div className={`relative ${props.className}`}>
				<button
					type={`${props.type ? props.type : 'button'}`}
					onClick={props.onClick}
					className={`${props.className ? props.className : ''} px-4 ${
						props.full ? 'w-full' : ''
					} ${`opacity-1 cursor-pointer ${btn.hover} hover:border ${btn.borderhover}`
					} ${btn.text} ${
						btn.normal
					} flex transition ease-in duration-150 justify-center py-3 border ${
						btn.border
					} rounded-xl shadow-sm text-sm font-medium focus:outline-none focus:ring-2
					${ringOffsetClass} focus:ring-offset-2 ${btn.focus}`}>
					{props.loading ? (
						<Loader className="mr-2" color={btn.loader}></Loader>
					) : (
						''
					)}
					{props.icon ? (
						<div className="ml-0 mr-2">
							<FontAwesomeIcon className={`${btn.text}`} icon={props.icon} />
						</div>
					) : (
						''
					)}
					<span className={`${btn.text}`}>{props.label}</span>
					{props.badge ? (
						<span
							className={`inline-flex items-center px-2 py-0.5 ml-2 rounded text-xs font-medium bg-gray-100 ${btn.badgeColor} ${btn.badgeTextColor}`}>
							{props.badge}
						</span>
					) : null}
				</button>
			</div>
		</>
	)
}
